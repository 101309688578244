import { NgModule } from "@angular/core";
import { PusherService } from "./pusher.service";
import { PusherComponent } from "app/shared/services/pusher/pusher.component";
import { MatIconModule } from "@angular/material/icon";
import { PusherErrorComponent } from "./components/digital-signature-error/pusher-error.component";

@NgModule({
    imports: [MatIconModule],
    providers: [PusherService],
    declarations: [PusherComponent, PusherErrorComponent],
})
export class PusherModule {}
