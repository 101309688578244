import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
    selector: "pusher",
    templateUrl: "./pusher.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["./pusher.component.scss"],
})
export class PusherComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        public data: {
            data: {
                id: string;
                minibank: string;
                assignor: string;
                value: string;
            };
            close: () => void;
        },
    ) {}

    dismiss() {
        this.data.close();
    }
}
