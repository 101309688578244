/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PusherComponent } from "app/shared/services/pusher/pusher.component";

@Injectable({
    providedIn: "root",
})
export class PusherProvider {
    constructor(readonly snack: MatSnackBar) {}

    openDialog(data: any) {
        const snackbar = this.snack.openFromComponent(PusherComponent, {
            data: {
                ...data,
                close: ()=> snackbar.dismiss(),
            },
            duration: 4000,
            verticalPosition: "bottom",
            horizontalPosition: "right",
            panelClass: ["snack-bar-custom"],
            direction: "ltr",
        });
    }
}
