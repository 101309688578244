/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import Pusher, { Channel } from "pusher-js";
import { environment } from "environments/environment";
import { PusherProvider } from "app/shared/services/pusher/pusher.provider";
import { PusherErrorNotificationProvider } from "./components/digital-signature-error/pusher-error.provider";

@Injectable({ providedIn: "root" })
export class PusherService {
    private pusher: Pusher;
    private channels: Channel[] = [];
    private channelsEvents: { [key: string]: Array<{ event: string; method: (data: any) => void }> } = {
        operations: [
            {
                event: "operation-pending-issues-resolved",
                method: (data) => this.notifyResolvedPendingIssues(data),
            },
            {
                event: "document-digital-signature-error",
                method: (data) => this.notifySignatureError(data),
            },
        ],
    };

    constructor(private pusherProvider: PusherProvider, private pusherErrorProvider: PusherErrorNotificationProvider) {
        this.init();
    }

    private init() {
        this.connect();
        this.mapChannelsEvents();
    }

    private connect() {
        localStorage.setItem("@bankme-channels", JSON.stringify({ operations: [] }));
        this.pusher = new Pusher(environment.vars.pusher, {
            cluster: "sa1",
        });
    }

    private mapChannelsEvents() {
        const pusherChannels = JSON.parse(localStorage.getItem("@bankme-channels")) as { [key: string]: string[] };
        if (!pusherChannels) return;
        Object.keys(pusherChannels).forEach((key) => {
            const channel = this.channelsEvents[key];
            if (!channel) return;
            this.addChannel(key);

            channel.forEach(({ event, method }) => {
                this.bindEvent(key, event, (data: string) => {
                    method(data);
                });
            });
        });
    }

    addChannel(channelName: string): void {
        this.channels.push(this.pusher.subscribe(channelName));
    }

    bindEvent<T>(channelName: string, eventName: string, callback: (data: T) => void): void {
        const channel = this.channels.find((c) => c.name === channelName);
        if (!channel) return;
        channel.bind(eventName, callback);
    }

    removeChannel(channelName: string): void {
        const channel = this.channels.find((c) => c.name === channelName);
        if (!channel) return;
        channel.unbind_all();
        channel.unsubscribe();
    }

    notifyResolvedPendingIssues(data: any) {
        this.pusherProvider.openDialog(data);
    }

    notifySignatureError(data: any) {
        this.pusherErrorProvider.openDialog({
            title: data.data.title || "Erro ao enviar documentos para assinatura",
            message: data.data.errorMessage || "Ocorreu um erro ao enviar documentos para assinatura.",
            operationId: data.data.operationId,
            minibank: data.data.minibank,
        });
    }
}
