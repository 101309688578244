import { Component } from "@angular/core";
import { PusherService } from "app/shared/services/pusher/pusher.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    constructor(private readonly pusher: PusherService) {}
}
