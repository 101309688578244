<div class="p-4 w-128 bg-white text-black">
    <div class="flex gap-2 items-center pr-2">
        <mat-icon [svgIcon]="'heroicons_outline:exclamation-circle'" class="text-error-500 icon-size-4"></mat-icon>
        <h2 class="font-semibold text-lg">{{ data.title }}</h2>

        <button class="ml-auto text-gray-600" (click)="dismiss()">X</button>
    </div>
    <div class="flex flex-col my-3">
        <p>
            Minibanco: <span class="text-gray-700">{{ data.minibank || "-" }}</span>
        </p>

        <p>
            Id da Operação: <span class="text-gray-700">{{ data.operationId || "-" }}</span>
        </p>

        <p>
            Erro: <span class="text-gray-700">{{ data.message }}</span>
        </p>
    </div>

    <a href="/operacoes/view/{{ data.operationId }}" class="font-bold text-primary-500">Acessar a operação</a>
</div>
