<div class="p-4 w-128 bg-white text-black">
    <div class="flex gap-2 items-center pr-2">
        <mat-icon [svgIcon]="'heroicons_outline:information-circle'" class="text-primary-500 icon-size-4"></mat-icon>
        <h2 class="font-semibold text-lg">Pendencias da Operação {{ data.data.id }} resolvida com sucesso!</h2>

        <button class="ml-auto text-gray-600" (click)="dismiss()">X</button>
    </div>
    <div class="flex flex-col my-3">
        <p>
            Minibanco: <span class="text-gray-700">{{ data.data.minibank }}</span>
        </p>

        <p>
            Cedente: <span class="text-gray-700">{{ data.data.assignor }}</span>
        </p>

        <p>
            Valor de face: <span class="text-gray-700">{{ data.data.value }}</span>
        </p>
    </div>

    <a href="/operacoes/view/{{ data.data.id }}" class="font-bold text-primary-500">Acessar a operação</a>
</div>
