import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
    selector: "pusher-error",
    templateUrl: "./pusher-error.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["./pusher-error.component.scss"],
})
export class PusherErrorComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        public data: {
            title: string;
            message: string;
            operationId: string;
            minibank?: string;
            close: () => void;
        },
    ) {}

    dismiss() {
        this.data.close();
    }
}
