import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PusherErrorComponent } from "./pusher-error.component";

@Injectable({
    providedIn: "root",
})
export class PusherErrorNotificationProvider {
    constructor(private readonly snack: MatSnackBar) {}

    openDialog({
        title,
        message,
        operationId,
        minibank,
    }: {
        title: string;
        message: string;
        operationId: string;
        minibank?: string;
    }) {
        const snackbar = this.snack.openFromComponent(PusherErrorComponent, {
            data: {
                title,
                message,
                operationId,
                minibank,
                close: () => snackbar.dismiss(),
            },
            duration: 5000,
            verticalPosition: "bottom",
            horizontalPosition: "right",
            panelClass: ["snack-bar-error"],
        });
    }
}
